import { ReactNode } from 'react';

import { Moment } from 'moment';

import { WaybillsEntry } from '../api/types';

export const foldView = <T>(data: T | null | undefined, loading: boolean, error: null | string) => (
    emptyNode: () => ReactNode,
    loadingNode: () => ReactNode,
    errorNode: (errorData: typeof error) => ReactNode,
    viewNode: (componentData: T) => ReactNode
): ReactNode => {
    if (loading) return loadingNode();
    if (error) return errorNode(error);
    if (!data || (Array.isArray(data) && data.length === 0)) return emptyNode();
    return viewNode(data);
};

export const withTimezone = (tz: string) => (date: Moment): Moment => date.tz(tz);

export const formatDate = (date: Moment): string => date.format('D MMMM');
export const formatTime = (date: Moment): string => date.format('HH:mm');
export const formatDatetime = (date: Moment): string => date.format('D MMMM, HH:mm');

export const formatPrice = (kopecks: number): string => {
    if (kopecks === 0) return '0 ₽';
    const value = (kopecks / 100).toFixed(2);
    const [left, right] = value.split('.');
    const priceChunk = [left.match(/.{1,3}(?=(.{3})*$)/g)?.join(' ') || 0, right === '00' ? undefined : right];
    return priceChunk.filter((i) => i !== undefined).join(',') + ' ₽';
};

export const foldWaybillsEntryUnit = (entry: WaybillsEntry): string => {
    const unitChunks = [`${entry.primary_quantity} ${entry.primary_measure_unit}`];

    if (entry.secondary_quantity_remainder && entry.secondary_measure_unit) {
        unitChunks.push(`${entry.secondary_quantity_remainder} ${entry.secondary_measure_unit}`);
    }

    return unitChunks.join(', ');
};
